let teasers = document.querySelectorAll('.teaser');

if (teasers.length > 0) {
  teasers.forEach(teaser => {
    let link = teaser.querySelectorAll('a')[0];
    let href = link.getAttribute('href');
    link.setAttribute('onclick', 'event.preventDefault()');
    teaser.onclick = function () {
      window.location.href = window.location.origin + '/' + href;
    };
  });
}

let teaserTrigger = document.querySelectorAll(".teaser-wrapper");

if (teaserTrigger.length > 0) {
  teaserTrigger.forEach(function (el) {
    el.addEventListener("click", function (event) {
      event.stopPropagation();
      el.classList.toggle("active");
    });
  });

  document.addEventListener("click", function () {
    closeDropdowns();
  });
}

function closeDropdowns() {
  teaserTrigger.forEach(function (el) {
    el.classList.remove("active");
  });
}

if(activeTeaser) {
  teaserTrigger.forEach(function (el) {
    el.classList.add("active");
  });
}


