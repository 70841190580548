let infoTrigger = document.querySelectorAll(".mobile-info");

if (infoTrigger.length > 0) {
    infoTrigger.forEach(function (el) {
    el.addEventListener("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      el.classList.toggle("open");
    });
  });

  /*
  document.addEventListener("click", function () {
    closeDropdowns();
  });
  */
}