
let prax1 = "praxis",
    app1 = "terminwunsch",
    part2 = Math.pow(2, 6),
    part3 = String.fromCharCode(part2),
    part4 = "dermatologie-speyer.de",
    prax5 = prax1 + String.fromCharCode(part2) + part4,
    app5 = app1 + String.fromCharCode(part2) + part4,
    elemPrax = document.getElementById("contact"),
    elemPraxImprint = document.getElementById("imprint"),
    elemApp = document.getElementById("appointment"),
    addressPrax = "Email: <a href=" + "mai" + "lto" + ":" + prax5 + ">" + prax1 + part3 + part4 + "</a>",
    addressPraxImprint = "<span>" + prax1 + part3 + part4 + "</span>",
    addressApp = "<a href=" + "mai" + "lto" + ":" + app5 + ">" + app1 + part3 + part4 + "</a>";

if (elemPrax) {
  elemPrax.innerHTML = addressPrax;
}
if (elemPraxImprint) {
  elemPraxImprint.innerHTML = addressPraxImprint;
}
if (elemApp) {
  elemApp.innerHTML = addressApp;
}

