const setMenuState = function (selector) {
  let menuItems = document.querySelectorAll(selector);
  if (menuItems.length > 0) {
    menuItems.forEach(item => {
      let hrefString = item.querySelector('a').getAttribute('href');
      let location = document.location.href;

      if (hrefString === location.substring(location.lastIndexOf('/')+1)) {
        item.classList.add('active');
        item.querySelector('a').classList.add('active');
        //item.setAttribute('onclick', 'event.preventDefault()');
      } else {
        item.classList.remove('active');
        item.querySelector('a').classList.remove('active');
      }
    });
  }
}

setMenuState('.main-nav .menu-item');
setMenuState('.teaser');

